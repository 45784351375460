var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-status flex flex-col items-center" }, [
    _c("img", {
      staticClass: "mb-16",
      attrs: { src: _vm.currentStatus.imgSrc }
    }),
    _c(
      "h2",
      { staticClass: "font-bold mb-16", class: _vm.currentStatus.textClass },
      [_vm._v("\n    " + _vm._s(_vm.currentStatus.statusText) + "\n  ")]
    ),
    _vm.paymentStatus === "fail_pay"
      ? _c(
          "div",
          { staticClass: "mt-8 mb-32" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-solid btn-orange font-bold no-underline",
                attrs: { to: { name: "Hotel" } }
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("backHome")) + "\n    ")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }