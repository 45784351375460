<template>
  <div class="modal__cancel-bank-account rounded-lg">
    <div class="modal__header mb-16 text-sm text-orange-dark font-bold p-16">
      <h4>{{ $t('refund.accountRequired') }}</h4>
    </div>
    <div class="modal__body p-16 pb-0">
      <div class="form-group" :class="{error: !formValidator.bank_code}">
        <label>{{ $t('refund.bankName') }}</label>
        <multiSelect
          v-model='bankSelected'
          track-by='code'
          label="name"
          :custom-label="customLabel"
          :options='bankList'
          :placeholder="$t('refund.choose')"
          :show-labels="false"
          :close-on-select="true"
          :allow-empty="false" />
      </div>
      <div class="form-group" :class="{error: !formValidator.bank_subcode}">
        <label>{{ $t('refund.subBankName') }}</label>
        <multiSelect
          v-model='subBankSelected'
          track-by='code'
          label="name"
          :custom-label="customLabel"
          :options='subBankList'
          :placeholder="$t('refund.choose')"
          :show-labels="false"
          :close-on-select="true"
          :allow-empty="false" />
      </div>
      <div class="form-group" :class="{error: !formValidator.bank_account}">
        <label>{{ $t('refund.bankAccount') }}</label>
        <input v-model="refundForm.bank_account" type="text" class="input">
      </div>
      <div class="form-group" :class="{error: !formValidator.bank_name}">
        <label>{{ $t('refund.accountName') }}</label>
        <input v-model="refundForm.bank_name" type="text" class="input">
      </div>
    </div>
    <div class="modal__footer flex w-full">
      <div v-for="(btn, index) in buttons" :key="index" @click="methodHandler(btn)" class="flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer">
        {{ btn.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
export default {
  name: 'modal-cancel-bank-account',
  props: {
    buttons: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      bankList: [],
      subBankList: [],
      bankSelected: '',
      subBankSelected: '',
      refundForm: {
        bank_code: '',
        bank_subcode: '',
        bank_account: '',
        bank_name: ''
      },
      formValidator: {
        bank_code: true,
        bank_subcode: true,
        bank_account: true,
        bank_name: true
      }
    }
  },
  watch: {
    bankSelected (val) {
      this.subBankList = []
      this.subBankSelected = ''
      this.getSubBankList(val.code)
    }
  },
  methods: {
    ...mapActions([
      'order/fetchBankList',
      'order/fetchSubBankList'
    ]),
    ...mapMutations([
      'order/SET_REFUND_FORM'
    ]),
    customLabel (bank) {
      return `${bank.code} ${bank.name}`
    },
    getBankList () {
      this['order/fetchBankList']()
        .then(res => {
          if (res.data.status === 0) {
            this.bankList = res.data.bankcode_list
          }
        })
    },
    getSubBankList (bankCode) {
      this['order/fetchSubBankList'](bankCode)
        .then(res => {
          if (res.data.status === 0) {
            this.subBankList = res.data.bankcode_list
          }
        })
    },
    beforeClose (event) {
      this.refundForm.bank_code = this.bankSelected.code
      this.refundForm.bank_subcode = this.subBankSelected.code

      // validate
      for (const key in this.formValidator) {
        this.formValidator[key] = !_.isEmpty(this.refundForm[key])
      }

      const validatePass = Object.value(this.formValidator).every(valid => valid)

      if (validatePass) {
        this.SET_REFUND_FORM(this.refundForm)
      } else {
        event.stop()
      }
    },
    methodHandler: function (btn) {
      if (btn.handler) {
        this.refundForm.bank_code = this.bankSelected.code
        this.refundForm.bank_subcode = this.subBankSelected.code

        // validate
        for (const key in this.formValidator) {
          this.formValidator[key] = !_.isEmpty(this.refundForm[key])
        }

        const validatePass = Object.values(this.formValidator).every(valid => valid)

        if (validatePass) {
          this['order/SET_REFUND_FORM'](this.refundForm)
          return btn.handler()
        } else {
          return false
        }
      } else {
        this.$emit('close')
      }
    }
  },
  mounted () {
    this.getBankList()
  }
}
</script>

<style lang="sass" scoped>
.form-group
  @apply mb-16
  label
    @apply block mb-8 text-sm text-grey-darkest
  input
    @apply w-full

  &.error
    label
      @apply text-red
</style>
