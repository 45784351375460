<template>
  <div class='hotel-order__item px-16 py-24 flex flex-col sm:flex-row justify-between items-start'>
    <div class="hotel-order__item__content mb-24 sm:mb-0">
      <h5 class='text-darkest mb-4'>
        {{ $convertI18nObj(plan.plan_name_i18n, plan.plan_name) }}
      </h5>
      <div class="text-sm mb-16 text-grey-darker">{{ plan.date }}</div>
    </div>
    <div class="hotel-order__item__amount w-full sm:w-auto">
      <table class='w-full sm:w-auto'>
        <tr>
          <td class='pb-8'>{{ $t('quantity') }}：</td>
          <td v-if="plan.unit" class='pb-8 text-right'>{{ $t('orderUnit', { stock: plan.qty, unit: $convertI18nObj(plan.unit_i18n, plan.unit) }) }}</td>
          <td v-else class='pb-8 text-right'>{{ plan.qty }} {{ $tc('room', plan.qty) }}</td>
        </tr>
        <tr v-if="hasExtraPersons">
          <td class='pb-8'>{{ $t('extraReception.addPerson') }}：</td>
          <td class='pb-8 text-right'>{{ `+${plan.extra_persons.adults || 0} ${$t('extraReception.adult')}, +${plan.extra_persons.children || 0} ${$t('extraReception.child')}` }}</td>
        </tr>
        <tr>
          <td>{{ $t('total') }}：</td>
          <td class='text-right'>
            <span class='text-xs'>{{ orderInfo.currency }} <big class='text-base text-blue font-medium'>{{ totalPriceIncludeExtraPersons | numeral }}</big></span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="text-right"><span class="block mt-8">({{ $t('room_price_has_tax_included') }})</span></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hotel-order',
  props: {
    plan: {
      type: Object
    },
    orderInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    dayRange: function () {
      const end = this.$dayjs(this.orderInfo.date_end_at)
      const start = this.$dayjs(this.orderInfo.date_start_at)
      return end.diff(start, 'day')
    },
    hasExtraPersons () {
      return this.plan?.extra_persons?.adults || this.plan?.extra_persons?.children
    },
    totalPriceIncludeExtraPersons () {
      if (this.plan?.extra_persons?.price) {
        return this.plan.total + this.plan.extra_persons.price
      }
      return this.plan.total
    }
  }
}
</script>

<style lang='sass' scoped>
.hotel-order__item
  &:not(:last-of-type)
    @apply border-b-1 border-grey-lighter
</style>
