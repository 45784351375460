<template>
  <div class='modal__content-width-cover rounded-lg'>
    <div class="modal__content-width-cover__content p-16 pb-8">
      <div
        class="modal__content-width-cover__content__cancel-policy">
        <h5 class='modal__content-width-cover__content__title text-grey-darkest mb-16'><i v-if="icon" class="modal__content-width-cover__content__title__iconStyle" :class="iconStyle"></i>{{ title }}</h5>
        <p class='text-sm text-grey-darker leading-normal' :class="isHtml ? 'whitespace-no-wrap' : 'whitespace-pre-wrap'" v-html="context"></p>
      </div>
    </div>
    <div class="cancelModal">
      <div class="p-16">
        <p class="mb-8 text-blue">{{ $t('cancelOrderConfirmWaringText') }}</p>
        <div class="mb-24 text-grey-darker leading-normal">
          <i18n
            path="cancelOrderCharge"
            tag="p"
          >
            <span place="fee" class="text-red">NT$ {{ cancelInfo.fee }}</span>
          </i18n>
        </div>
        <div class="table">
          <div class="thead">
            <div class="tr">
              <div class="td">{{ $t('cancelOrderPayTotal') }}</div>
              <div class="td">{{ $t('cancelOrderRefundPercentage') }}</div>
              <div class="td">{{ $t('cancelOrderRefundFee') }}</div>
              <div class="td">{{ $t('cancelOrderRefundTotal') }}</div>
            </div>
          </div>
          <div class="tbody">
            <div class="tr">
              <div class="td">{{ cancelInfo.total }}</div>
              <div class="td">{{ percentage }}</div>
              <div class="td text-red">{{ cancelInfo.fee }}</div>
              <div class="td">{{ cancelInfo.cancelTotal }}</div>
            </div>
          </div>
        </div>
        <hr>
        <template v-if="cancelInfo.isDeposit">
          <h5 class="mb-8 text-grey-darker font-bold">{{ $t('cancelOrderRefundDepositExplanation') }}</h5>
          <div class="table">
            <div class="thead">
              <div class="tr">
                <div class="td">{{ $t('cancelOrderRefundTime') }}</div>
                <div class="td">{{ $t('cancelOrderRefundPercentage') }}</div>
              </div>
            </div>
            <div class="tbody">
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 14}">
                <div class="td">{{ $t('cancelOrderRefundMoreThan14') }}</div>
                <div class="td">100%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 10 && cancelInfo.diffDays < 14}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '10 - 13' }) }}</div>
                <div class="td">70%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 7 && cancelInfo.diffDays < 10}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '7 - 9' }) }}</div>
                <div class="td">50%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 4 && cancelInfo.diffDays < 7}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '4 - 6' }) }}</div>
                <div class="td">40%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 2 && cancelInfo.diffDays < 4}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '2 - 3' }) }}</div>
                <div class="td">30%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays === 1}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '1' }) }}</div>
                <div class="td">20%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays === 0 || cancelInfo.diffDays < 0}">
                <div class="td">{{ $t('cancelOrderRefundNow') }}</div>
                <div class="td">{{ $t('cancelOrderNoRefund') }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <h5 class="mb-8 text-grey-darker font-bold">{{ $t('cancelOrderRefundRatioExplanation') }}</h5>
          <div class="table">
            <div class="thead">
              <div class="tr">
                <div class="td">{{ $t('cancelOrderRefundTime') }}</div>
                <div class="td">{{ $t('cancelOrderRefundPercentage') }}</div>
              </div>
            </div>
            <div class="tbody">
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 3}">
                <div class="td">{{ $t('cancelOrderRefundMoreThan3') }}</div>
                <div class="td">100%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays >= 1 && cancelInfo.diffDays < 3}">
                <div class="td">{{ $t('cancelOrderRefundBetween', { duration: '1 - 2' }) }}</div>
                <div class="td">50%</div>
              </div>
              <div class="tr large" :class="{actived: cancelInfo.diffDays === 0 || cancelInfo.diffDays < 0}">
                <div class="td">{{ $t('cancelOrderRefundNow') }}</div>
                <div class="td">{{ $t('cancelOrderNoRefund') }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="modal__content-width-cover__footer flex w-full">
      <template v-if="isCustomButtons">
        <div v-for="(btn, index) in buttons" :key="index" @click="methodHandler(btn)" class="flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer">
          {{ btn.title }}
        </div>
      </template>
      <div
        v-else
        class="flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer"
        @click="$emit('close')">
        {{ $t('close') }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import i18n from '@/i18n'
export default {
  name: 'modal-content-width-cover',
  props: {
    title: {
      type: String,
      default: () => i18n.t('cancelPolicy')
    },
    cancelInfo: {
      type: Object,
      default: () => {
        return {
          total: 0,
          fee: 50,
          cancelTotal: 0,
          isDeposit: false
        }
      }
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    isFeatures: {
      type: Boolean,
      default: false
    },
    features: {
      type: Array,
      default: () => []
    },
    context: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    isScroll: {
      type: Boolean,
      default: true
    },
    buttons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    percentage: function () {
      if (this.cancelInfo.isDeposit) {
        if (this.cancelInfo.diffDays >= 14) {
          return '100%'
        }
        if (this.cancelInfo.diffDays >= 10 && this.cancelInfo.diffDays < 14) {
          return '70%'
        }
        if (this.cancelInfo.diffDays >= 7 && this.cancelInfo.diffDays < 10) {
          return '50%'
        }
        if (this.cancelInfo.diffDays >= 4 && this.cancelInfo.diffDays < 7) {
          return '40%'
        }
        if (this.cancelInfo.diffDays >= 2 && this.cancelInfo.diffDays < 4) {
          return '30%'
        }
        if (this.cancelInfo.diffDays === 1) {
          return '20%'
        }
        if (this.cancelInfo.diffDays === 0 || this.cancelInfo.diffDays < 0) {
          return this.$t('cancelOrderNoRefund')
        }
      } else {
        if (this.cancelInfo.diffDays >= 3) {
          return '100%'
        }
        if (this.cancelInfo.diffDays >= 1 && this.cancelInfo.diffDays < 3) {
          return '50%'
        }
        if (this.cancelInfo.diffDays === 0 || this.cancelInfo.diffDays < 0) {
          return this.$t('cancelOrderNoRefund')
        }
      }
      return '---'
    },
    isCoverImage: function () {
      return !_.isEmpty(this.img)
    },
    isHtml: function () {
      return /<[a-z][\s\S]*>/i.test(this.context)
    },
    iconStyle: function () {
      const styles = [
        !_.isEmpty(this.icon) ? this.icon : '',
        !_.isEmpty(this.type) ? this.type : ''
      ]
      return styles
    },
    isCustomButtons: function () {
      return !_.isEmpty(this.buttons)
    }
  },
  methods: {
    methodHandler: function (btn) {
      if (btn.handler) {
        return btn.handler()
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang='sass' scoped>
.modal__content-width-cover
  // width: 100%
  // max-width: 450px
  &__cover
    height: 170px
    @apply bg-grey-lightest bg-center bg-no-repeat bg-cover
  &__content
    max-height: 400px
    -webkit-overflow-scrolling: touch
    overflow-y: auto
    ul
      @apply pl-24
    &__title
      @apply flex flex-wrap items-center
      &__iconStyle
        @apply mr-4 text-2xl inline-block
        &.success
          @apply text-green
        &.warning
          @apply text-orange
        &.error
          @apply text-red
  .tr.large
    min-width: 100%
    &.actived
      @apply text-white bg-red font-bold
  &__footer
    @apply border-t-1 border-grey-light
.cancelModal
  max-height: 60vh
  overflow-y: auto
  @apply bg-grey-lightest
  .table .thead .td
    padding: 16px 12px
  .table .tbody .tr .td
    padding: 12px
  .tr.actived:hover
    .td
      @apply bg-red
</style>
