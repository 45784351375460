<template>
  <div class='addon-order__item'>
    <div class="addon-order__item__head px-16 py-24 border-b-1 border-grey-lighter">
      <h5 class='text-darkest mb-16'>{{ addonName | contentParser }}</h5>
      <div class="addon-order__item__head__row flex flex-col sm:flex-row justify-between items-start text-grey-dark">
        <div class="addon-order__item__head__row__content mb-24 sm:mb-0">
          <!-- 美國需求隱藏含稅單價 -->
          <!-- <span class='block text-xs font-medium'>{{ orderInfo.currency }} <big>{{ perItemTotalPrice | numeral }}</big></span> -->
        </div>
        <div class="addon-order__item__head__row__amount w-full sm:w-auto flex justify-between sm:justify-end items-center text-sm flex-wrap">
          <label class='mr-32'>{{ $t('total') }}: </label>
          <span class='text-xs'>{{ orderInfo.currency }} <big class='text-base text-blue font-medium'>{{ addon.total | numeral }}</big></span>
          <span v-if="isIncludesTaxFee" class="w-full text-right mt-8">({{ $t('price_has_tax_included') }})</span>
        </div>
      </div>
    </div>
    <div class="addon-order__item__body px-16">
      <div
        v-for='(group, index) in addon.group'
        :key='index'
        class="addon-order__item__body__row py-16 flex text-grey-darkest text-sm flex justify-between">
        <span class='mr-auto flex-1'><span>{{group.date}} {{group.session}}</span></span>
        <span v-if="addon.unit" class='mr-40'>{{ group.qty }} {{ $convertI18nObj(addon.unit_i18n, addon.unit) }}</span>
        <span v-else class='mr-40'>{{ group.qty }} 人</span>
        <span>{{ orderInfo.currency }} {{ group.price * group.qty | numeral }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addon-order',
  props: {
    addon: {
      type: [Object, Array]
    },
    orderInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    addonName: function () {
      return this.$convertI18nObj(this.addon.name_i18n, this.addon.name)
    },
    totalPrice: function () {
      if (this.addon.length === 1) {
        return this.addon[0].total
      } else {
        return this.addon.reduce((sum, curr) => {
          return sum + (this.$numeral(curr.total).value() * curr.qty)
        }, 0)
      }
    },
    perItemTotalPrice: function () {
      const averagePrice = this.addon.group.reduce((sum, item) => sum + (+item.price + item.fee), 0) / this.addon.group.length
      const staticPrice = this.addon.group[0]?.price + (this.addon.group[0]?.fee / this.addon.group[0]?.qty) // 因為 api response 的 fee 會是 qty 的總和，price 則為單項 => (單項的 fee) + (單項的 price)
      // 若為 exp 可能有不同日不同價的問題需取平均價，其他則為固定單價
      return this.addon.type === 'exp'
        ? averagePrice
        : staticPrice
    },
    isIncludesTaxFee: function () {
      return this.addon.total !== this.addon.price
    }
  }
}
</script>

<style lang='sass' scoped>
.addon-order__item
  &:not(:last-of-type)
    @apply border-b-1 border-grey-lighter

  &__body__row
    &:not(:last-of-type)
      @apply border-b-1 border-grey-lighter
</style>
