var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__content-width-cover rounded-lg" }, [
    _c(
      "div",
      { staticClass: "modal__content-width-cover__content p-16 pb-8" },
      [
        _c(
          "div",
          { staticClass: "modal__content-width-cover__content__cancel-policy" },
          [
            _c(
              "h5",
              {
                staticClass:
                  "modal__content-width-cover__content__title text-grey-darkest mb-16"
              },
              [
                _vm.icon
                  ? _c("i", {
                      staticClass:
                        "modal__content-width-cover__content__title__iconStyle",
                      class: _vm.iconStyle
                    })
                  : _vm._e(),
                _vm._v(_vm._s(_vm.title))
              ]
            ),
            _c("p", {
              staticClass: "text-sm text-grey-darker leading-normal",
              class: _vm.isHtml ? "whitespace-no-wrap" : "whitespace-pre-wrap",
              domProps: { innerHTML: _vm._s(_vm.context) }
            })
          ]
        )
      ]
    ),
    _c("div", { staticClass: "cancelModal" }, [
      _c(
        "div",
        { staticClass: "p-16" },
        [
          _c("p", { staticClass: "mb-8 text-blue" }, [
            _vm._v(_vm._s(_vm.$t("cancelOrderConfirmWaringText")))
          ]),
          _c(
            "div",
            { staticClass: "mb-24 text-grey-darker leading-normal" },
            [
              _c("i18n", { attrs: { path: "cancelOrderCharge", tag: "p" } }, [
                _c(
                  "span",
                  { staticClass: "text-red", attrs: { place: "fee" } },
                  [_vm._v("NT$ " + _vm._s(_vm.cancelInfo.fee))]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "table" }, [
            _c("div", { staticClass: "thead" }, [
              _c("div", { staticClass: "tr" }, [
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderPayTotal")))
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderRefundPercentage")))
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderRefundFee")))
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderRefundTotal")))
                ])
              ])
            ]),
            _c("div", { staticClass: "tbody" }, [
              _c("div", { staticClass: "tr" }, [
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.cancelInfo.total))
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.percentage))
                ]),
                _c("div", { staticClass: "td text-red" }, [
                  _vm._v(_vm._s(_vm.cancelInfo.fee))
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v(_vm._s(_vm.cancelInfo.cancelTotal))
                ])
              ])
            ])
          ]),
          _c("hr"),
          _vm.cancelInfo.isDeposit
            ? [
                _c("h5", { staticClass: "mb-8 text-grey-darker font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderRefundDepositExplanation")))
                ]),
                _c("div", { staticClass: "table" }, [
                  _c("div", { staticClass: "thead" }, [
                    _c("div", { staticClass: "tr" }, [
                      _c("div", { staticClass: "td" }, [
                        _vm._v(_vm._s(_vm.$t("cancelOrderRefundTime")))
                      ]),
                      _c("div", { staticClass: "td" }, [
                        _vm._v(_vm._s(_vm.$t("cancelOrderRefundPercentage")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "tbody" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: { actived: _vm.cancelInfo.diffDays >= 14 }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderRefundMoreThan14")))
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("100%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays >= 10 &&
                            _vm.cancelInfo.diffDays < 14
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "10 - 13"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("70%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays >= 7 &&
                            _vm.cancelInfo.diffDays < 10
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "7 - 9"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("50%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays >= 4 &&
                            _vm.cancelInfo.diffDays < 7
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "4 - 6"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("40%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays >= 2 &&
                            _vm.cancelInfo.diffDays < 4
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "2 - 3"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("30%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: { actived: _vm.cancelInfo.diffDays === 1 }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "1"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("20%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays === 0 ||
                            _vm.cancelInfo.diffDays < 0
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderRefundNow")))
                        ]),
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderNoRefund")))
                        ])
                      ]
                    )
                  ])
                ])
              ]
            : [
                _c("h5", { staticClass: "mb-8 text-grey-darker font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("cancelOrderRefundRatioExplanation")))
                ]),
                _c("div", { staticClass: "table" }, [
                  _c("div", { staticClass: "thead" }, [
                    _c("div", { staticClass: "tr" }, [
                      _c("div", { staticClass: "td" }, [
                        _vm._v(_vm._s(_vm.$t("cancelOrderRefundTime")))
                      ]),
                      _c("div", { staticClass: "td" }, [
                        _vm._v(_vm._s(_vm.$t("cancelOrderRefundPercentage")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "tbody" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: { actived: _vm.cancelInfo.diffDays >= 3 }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderRefundMoreThan3")))
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("100%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays >= 1 &&
                            _vm.cancelInfo.diffDays < 3
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("cancelOrderRefundBetween", {
                                duration: "1 - 2"
                              })
                            )
                          )
                        ]),
                        _c("div", { staticClass: "td" }, [_vm._v("50%")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tr large",
                        class: {
                          actived:
                            _vm.cancelInfo.diffDays === 0 ||
                            _vm.cancelInfo.diffDays < 0
                        }
                      },
                      [
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderRefundNow")))
                        ]),
                        _c("div", { staticClass: "td" }, [
                          _vm._v(_vm._s(_vm.$t("cancelOrderNoRefund")))
                        ])
                      ]
                    )
                  ])
                ])
              ]
        ],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "modal__content-width-cover__footer flex w-full" },
      [
        _vm.isCustomButtons
          ? _vm._l(_vm.buttons, function(btn, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.methodHandler(btn)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(btn.title) + "\n      ")]
              )
            })
          : _c(
              "div",
              {
                staticClass:
                  "flex-1 border-t-1 border-grey-lighter text-center text-grey py-16 cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("close")) + "\n    ")]
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }