<template>
  <div class="order-status flex flex-col items-center">
    <img
      :src="currentStatus.imgSrc"
      class="mb-16"
    >
    <h2
      :class="currentStatus.textClass"
      class="font-bold mb-16"
    >
      {{ currentStatus.statusText }}
    </h2>
    <div v-if="paymentStatus === 'fail_pay'" class="mt-8 mb-32">
      <router-link
        :to="{ name: 'Hotel' }"
        class="btn btn-solid btn-orange font-bold no-underline"
      >
        {{ $t('backHome') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paymentMethodType: {
      type: String,
      default: 'other'
    },
    firstPaymentStatus: {
      type: String,
      default: ''
    },
    paymentStatus: {
      type: String,
      default: ''
    }
  },
  computed: {
    statusConfig () {
      const colorfulImg = require('@/assets/img/hotel-img.png')
      const greyImg = require('@/assets/img/hotel-img-grey.png')

      const textGrey = 'text-grey-dark'
      const textOrange = 'text-orange'
      const textGreen = 'text-green-dark'
      const textRed = 'text-red-dark'

      const statusConfig = {
        orderIsCanceled: {
          imgSrc: greyImg,
          textClass: textGrey,
          statusText: this.$t('orderIsCanceled') // 訂單已取消
        },
        orderSuccessMsg: {
          imgSrc: colorfulImg,
          textClass: textGreen,
          statusText: this.$t('orderSuccessMsg') // 恭喜您完成訂購！
        },
        orderUnPaid: {
          imgSrc: greyImg,
          textClass: textOrange,
          statusText: this.$t('orderUnPaid') // 訂單成立，等待付款
        },
        orderDepositUnPaid: {
          imgSrc: greyImg,
          textClass: textOrange,
          statusText: this.$t('orderDepositUnPaid') // 訂單成立，待付訂金
        },
        orderNotFinish: {
          imgSrc: colorfulImg,
          textClass: textGreen,
          statusText: this.$t('orderNotFinish') // 訂單成立，訂金已付
        },
        waitingForRefunds: {
          imgSrc: greyImg,
          textClass: textGrey,
          statusText: this.$t('waitingForRefunds') // 等待退款中
        },
        orderHadPaidDepositMsg: {
          imgSrc: colorfulImg,
          textClass: textGreen,
          statusText: this.$t('orderHadPaidDepositMsg') // 已付訂金，尾款待結清
        },
        orderNotCompleted: {
          imgSrc: greyImg,
          textClass: textOrange,
          statusText: this.$t('orderNotCompleted') // 訂購尚未完成
        },
        orderSuccessUnpaid: {
          imgSrc: colorfulImg,
          textClass: textOrange,
          statusText: this.$t('orderSuccessUnpaid') // 訂單成立，付款尚未完成
        },
        orderFailPay: {
          imgSrc: greyImg,
          textClass: textRed,
          statusText: this.$t('orderFailPay') // 付款失敗
        },
        orderExpired: {
          imgSrc: greyImg,
          textClass: textRed,
          statusText: this.$t('orderExpired')
        }
      }
      return statusConfig
    },
    currentStatusKey () {
      const cancelStatus = ['cancel', 'partial_refund', 'refund', 'wait_refund']
      // 取消與退款一率顯示已取消
      if (cancelStatus.includes(this.paymentStatus)) {
        return 'orderIsCanceled'
      }

      if (this.paymentMethodType === 'collection') {
        // 代收金流系列
        // 有收到first payment
        if (this.firstPaymentStatus === 'paid') {
          return 'orderSuccessMsg'
        }
        // 未收到first payment
        return 'orderNotCompleted'
      } else if (this.paymentMethodType === 'non-collection') {
        // 非代收金流系列
        // 完成訂購
        return 'orderSuccessMsg'
      } else {
        // 其他
        if (this.paymentMethodType === 'pay-at-hotel') {
          return 'orderSuccessMsg'
        }
      }

      return 'orderSuccessMsg'
    },
    currentStatus ({ currentStatusKey }) {
      return this.statusConfig[currentStatusKey] || {}
    }
  }
}
</script>
