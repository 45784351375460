var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "complete mt-40" },
    [
      _vm.isOrderLoading
        ? _c("loading")
        : _c("div", [
            !_vm.hasOrder
              ? _c(
                  "div",
                  { staticClass: "container mb-80" },
                  [
                    _c("blockCard", [
                      _c(
                        "div",
                        {
                          staticClass: "p-24 flex flex-col items-center",
                          attrs: { id: "invalid-order" }
                        },
                        [
                          _c("img", {
                            staticClass: "mb-32",
                            attrs: {
                              src: require("@/assets/img/hotel-img-grey.png"),
                              alt: ""
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "empty-notice flex items-center" },
                            [
                              _c("i", {
                                staticClass:
                                  "owl-status-triangle-warning text-orange text-2xl"
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-2xl font-medium text-grey-darkest"
                                },
                                [_vm._v(_vm._s(_vm.$t("checkNoOrder")))]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c("paymentStatusWithCoverImg", {
                      attrs: {
                        "payment-method-type": _vm.typeOfPaymentMethod,
                        "first-payment-status": _vm.firstPaymentStatus,
                        "payment-status": _vm.paymentStatus
                      }
                    }),
                    _vm.orderInfo.atm_expired_time_at &&
                    _vm.isATM &&
                    (_vm.paymentStatus === "unpaid" ||
                      _vm.paymentStatus === "deposit_unpaid")
                      ? _c("blockCard", { staticClass: "bg-orange-lightest" }, [
                          _c(
                            "div",
                            { staticClass: "notice flex items-center mb-24" },
                            [
                              _c("i", {
                                staticClass:
                                  "owl-status-triangle-warning text-orange text-xl mr-8"
                              }),
                              _c(
                                "span",
                                { staticClass: "text-grey-darkest text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("ifNotPaidAt[0]")) + " "
                                  ),
                                  _c(
                                    "mark",
                                    {
                                      staticClass:
                                        "bg-transparent text-blue font-bold"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.orderInfo.atm_expired_time_at
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.$t("ifNotPaidAt[1]")))
                                ]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "leading-normal text-grey-darkest text-sm whitespace-pre-wrap"
                            },
                            [_vm._v(_vm._s(_vm.$t("atmMsg")))]
                          )
                        ])
                      : _vm._e(),
                    _vm.orderInfo.atm_expired_time_at &&
                    _vm.isAtmHotel &&
                    (_vm.paymentStatus === "unpaid" ||
                      _vm.paymentStatus === "deposit_unpaid")
                      ? _c("blockCard", { staticClass: "bg-orange-lightest" }, [
                          _c(
                            "div",
                            { staticClass: "notice flex items-center mb-24" },
                            [
                              _c("i", {
                                staticClass:
                                  "owl-status-triangle-warning text-orange text-xl mr-8"
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-grey-darkest text-sm whitespace-pre-wrap"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ifIsAtmHotelAndNotPaidAt[0]")
                                    ) + " "
                                  ),
                                  _c(
                                    "mark",
                                    {
                                      staticClass:
                                        "bg-transparent text-blue font-bold"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.orderInfo.atm_expired_time_at
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("ifIsAtmHotelAndNotPaidAt[1]")
                                      )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "leading-normal text-grey-darkest text-sm whitespace-pre-wrap"
                            },
                            [_vm._v(_vm._s(_vm.$t("ifIsAtmHotelAndAtmMsg")))]
                          )
                        ])
                      : _vm._e(),
                    _vm.isCancelButton
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-right my-16 p-16 border border-grey-lighter rounded"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-ghost btn-orange",
                                attrs: { disabled: _vm.isCancelingOrder },
                                on: { click: _vm.onCancelbuttonClick }
                              },
                              [
                                _vm.isCancelingOrder
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("isCanceling")) +
                                          "\n          "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("cancelOrder")) +
                                          "\n          "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.showTransferInfo
                      ? _c(
                          "blockCard",
                          {
                            staticClass: "transfer-info",
                            attrs: { noPadding: "" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "transfer-info__detail py-24 lg:p-24 border-b-1 border-grey-lighter"
                              },
                              [
                                _c("h4", { staticClass: "mb-24" }, [
                                  _vm._v(_vm._s(_vm.$t("transferInformation")))
                                ]),
                                _c("div", { staticClass: "flex-table mb-24" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex-table__row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-table__row__cell"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("transferBank")) +
                                              "："
                                          )
                                        ]
                                      ),
                                      _vm.isAtmHotel
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.orderInfo
                                                      .atm_bank_code +
                                                      " " +
                                                      _vm.orderInfo
                                                        .atm_bank_name
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                ( " +
                                                  _vm._s(
                                                    _vm.orderInfo
                                                      .atm_bank_sub_code +
                                                      " " +
                                                      _vm.orderInfo
                                                        .atm_bank_sub_name
                                                  ) +
                                                  " )\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("cathayBank"))
                                              )
                                            ]
                                          )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex-table__row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-table__row__cell"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("accountName")) + "："
                                          )
                                        ]
                                      ),
                                      _vm.isAtmHotel
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderInfo.atm_account_name
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("owltingTraveAgency")
                                                )
                                              )
                                            ]
                                          )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex-table__row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-table__row__cell"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("accountNumber")) +
                                              "："
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-table__row__cell"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.orderInfo.atm_account)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.orderInfo.atm_expired_time_at
                                    ? _c(
                                        "div",
                                        { staticClass: "flex-table__row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("transferPeriod")
                                                ) + "："
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-table__row__cell"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderInfo
                                                    .atm_expired_time_at
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "transfer-info__detail__total-amount flex items-end"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-base text-grey-darkest mr-8"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("transferAmount")) + " "
                                        ),
                                        _vm.isDeposit
                                          ? _c("span", [
                                              _vm._v(
                                                "（" +
                                                  _vm._s(_vm.$t("deposit")) +
                                                  "）"
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v("：")
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-xl text-blue font-bold"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.orderInfo.currency) +
                                            " " +
                                            _vm._s(
                                              _vm._f("numeral")(_vm.ATMTotal)
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm.isAtmHotel
                                  ? [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "pt-16 mt-24 border-t border-grey-lighter"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("paymentConfirm"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transfer-info__detail__total-amount flex items-end mt-8"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-8 mb-16" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-base text-grey-darkest mr-8 mb-8"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "accountLast5Numbers"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _vm.validation.firstError(
                                                        "atmPaidAccount"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-red text-sm"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "*" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "errorMsg.length",
                                                                      [5]
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.paymentStatus ===
                                                    "deposit_unpaid" ||
                                                  _vm.paymentStatus === "unpaid"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.atmPaidAccount,
                                                            expression:
                                                              "atmPaidAccount"
                                                          }
                                                        ],
                                                        staticClass: "input",
                                                        attrs: {
                                                          name:
                                                            "atmPaidAccount",
                                                          type: "tel",
                                                          maxlength: "5",
                                                          disabled:
                                                            _vm.isOnClikcCheckAccount
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.atmPaidAccount
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.atmPaidAccount =
                                                              $event.target.value
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.paymentStatus ===
                                                    "not_finish" ||
                                                  _vm.paymentStatus === "paid"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input text-grey-darker italic text-sm"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.atmPaidAccount
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "my-16" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-base text-grey-darkest mr-8 mb-8"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "paymentDate"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _vm.validation.firstError(
                                                        "atmPaidDate"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-red text-sm"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "*" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "errorMsg.empty"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.paymentStatus ===
                                                    "deposit_unpaid" ||
                                                  _vm.paymentStatus === "unpaid"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.atmPaidDate,
                                                            expression:
                                                              "atmPaidDate"
                                                          }
                                                        ],
                                                        staticClass: "input",
                                                        attrs: {
                                                          name: "atmPaidDate",
                                                          type: "date",
                                                          disabled:
                                                            _vm.isOnClikcCheckAccount
                                                        },
                                                        domProps: {
                                                          value: _vm.atmPaidDate
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.atmPaidDate =
                                                              $event.target.value
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.paymentStatus ===
                                                    "not_finish" ||
                                                  _vm.paymentStatus === "paid"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input text-grey-darker italic text-sm"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.atmPaidDate
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "my-16" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-base text-grey-darkest mr-8 mb-8"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "paymentAmount"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _vm.validation.firstError(
                                                        "atmPaidTotal"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-red text-sm"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "*" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "errorMsg.empty"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.paymentStatus ===
                                                    "deposit_unpaid" ||
                                                  _vm.paymentStatus === "unpaid"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.atmPaidTotal,
                                                            expression:
                                                              "atmPaidTotal"
                                                          }
                                                        ],
                                                        staticClass: "input",
                                                        attrs: {
                                                          name: "atmPaidTotal",
                                                          type: "number",
                                                          disabled:
                                                            _vm.isOnClikcCheckAccount
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.atmPaidTotal
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.atmPaidTotal =
                                                              $event.target.value
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.paymentStatus ===
                                                    "not_finish" ||
                                                  _vm.paymentStatus === "paid"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input text-grey-darker italic text-sm"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.atmPaidTotal
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "my-16" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-base text-grey-darkest mr-8 mb-8"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t("captcha")
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _vm.validation.firstError(
                                                        "userCaptcha"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-red text-sm"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "*" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "errorMsg.empty"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.paymentStatus ===
                                                    "deposit_unpaid" ||
                                                  _vm.paymentStatus === "unpaid"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex items-center"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "captcha",
                                                              class: {
                                                                "captcha--loading":
                                                                  _vm.isCaptchaLoading
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "captcha__img",
                                                                attrs: {
                                                                  src:
                                                                    _vm.captchaImg
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-text btn-grey",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isCaptchaLoading
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.fetchCaptcha
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "owl-arrow-update"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.paymentStatus ===
                                                    "deposit_unpaid" ||
                                                  _vm.paymentStatus === "unpaid"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.userCaptcha,
                                                            expression:
                                                              "userCaptcha"
                                                          }
                                                        ],
                                                        staticClass: "input",
                                                        attrs: {
                                                          name: "userCaptcha",
                                                          disabled:
                                                            _vm.isOnClikcCheckAccount ||
                                                            _vm.isCaptchaLoading
                                                        },
                                                        domProps: {
                                                          value: _vm.userCaptcha
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.userCaptcha =
                                                              $event.target.value
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm.paymentStatus ===
                                                "deposit_unpaid" ||
                                              _vm.paymentStatus === "unpaid"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-solid btn-orange px-16",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isOnClikcCheckAccount
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.onClikcCheckAccount
                                                      }
                                                    },
                                                    [
                                                      _vm.isOnClikcCheckAccount
                                                        ? _c("span", [
                                                            _c("i", {
                                                              staticClass:
                                                                "owl-arrow-reload animate-spin"
                                                            })
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("sent")
                                                              )
                                                            )
                                                          ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "transfer-info__notice py-24 lg:p-24"
                              },
                              [
                                _vm.isAtmHotel
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "notice flex items-center mb-8"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "owl-status-triangle-warning text-orange text-xl mr-8"
                                          }),
                                          _c("span", {
                                            staticClass: "mr-4",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "provideAccountLast5NumbersAfterTransfer"
                                                )
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "notice flex items-center mb-8"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "owl-status-triangle-warning text-orange text-xl mr-8"
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-grey-darkest text-sm"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "transferAccountWarning"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "notice flex items-center"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "owl-status-triangle-warning text-orange text-xl mr-8"
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-grey-darkest text-sm"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("ifCancelWarning")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "blockCard",
                      { staticClass: "order-info", attrs: { noPadding: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "order-info__detail py-24 lg:p-24 border-b-1 border-grey-lighter"
                          },
                          [
                            _c("h4", { staticClass: "mb-24" }, [
                              _vm._v(_vm._s(_vm.$t("orderInformation")))
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order-info__detail__date-participant text-grey-darkest mb-32"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "order-info__detail__date-participant mb-16"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "owl-calendar mr-8 text-grey text-xl"
                                    }),
                                    _c("span", [_vm._v(_vm._s(_vm.dateRange))]),
                                    _c(
                                      "small",
                                      { staticClass: "ml-8 text-grey-dark" },
                                      [
                                        _vm._v(
                                          "( " +
                                            _vm._s(_vm.diffDays + 1) +
                                            " " +
                                            _vm._s(
                                              _vm.$tc("day", _vm.diffDays + 1)
                                            ) +
                                            " " +
                                            _vm._s(_vm.diffDays) +
                                            " " +
                                            _vm._s(
                                              _vm.$tc("night", _vm.diffDays)
                                            ) +
                                            " )"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "order-info__detail__date-participant"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "owl-user-group mr-8 text-grey text-xl"
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.orderInfo.guest.adult) +
                                          " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "adult",
                                              _vm.orderInfo.guest.adult
                                            )
                                          )
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ", " +
                                          _vm._s(_vm.orderInfo.guest.children) +
                                          " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "child",
                                              _vm.orderInfo.guest.children
                                            )
                                          )
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        ", " +
                                          _vm._s(_vm.orderInfo.guest.infant) +
                                          " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "infant",
                                              _vm.orderInfo.guest.infant
                                            )
                                          )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "template__info-group mb-24" },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("orderNumber")))
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.orderInfo.order_serial))
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "template__info-group mb-24" },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("orderNights")))
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.diffDaysCount) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc("night", _vm.diffDaysCount)
                                      )
                                  )
                                ])
                              ]
                            ),
                            _vm.hasUsedCoupon
                              ? _c(
                                  "div",
                                  { staticClass: "template__info-group mb-24" },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("couponApplied")))
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "used-coupons flex" },
                                      _vm._l(_vm.orderInfo.coupons, function(
                                        coupon,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: coupon.code + index,
                                            staticClass:
                                              "coupon-container w-full md:w-1/3"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "coupon-detail border-1 border-grey-lightest shadow-lg p-16 font-medium"
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("roomTotal"))
                                                  )
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    "- " +
                                                      _vm._s(
                                                        _vm.couponDiscountValue(
                                                          coupon
                                                        )
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order-info__detail__amounts flex flex-col md:flex-row"
                              },
                              [
                                _vm.isShowRemainTotal
                                  ? _c(
                                      "div",
                                      { staticClass: "template__info-group" },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.$t("remainTotal")))
                                        ]),
                                        _c(
                                          "span",
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orderInfo.currency) +
                                                " "
                                            ),
                                            _c(
                                              "big",
                                              {
                                                staticClass:
                                                  "text-blue font-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("numeral")(
                                                      _vm.remainTotal
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.typeOfPaymentMethod === "collection"
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "template__info-group" },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("amountPaid")))
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.orderInfo.currency) +
                                                "\n                  " +
                                                _vm._s(
                                                  _vm._f("numeral")(
                                                    _vm.paidDepositTotal
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "template__info-group" },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("total")))
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex flex-col md:flex-row"
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.orderInfo.currency) +
                                              " " +
                                              _vm._s(
                                                _vm._f("numeral")(
                                                  _vm.orderInfo.order_total
                                                )
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              2
                            ),
                            _vm.hasExtraTaxFeeConfig
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "text-sm mt-16 text-grey-dark"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "owl-status-triangle-warning text-orange"
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("room_price_has_tax_included")
                                        ) +
                                        " " +
                                        _vm._s(_vm.orderInfo.currency) +
                                        " " +
                                        _vm._s(
                                          _vm._f("numeral")(_vm.totalExtraFee)
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "order-info__orderer py-24 lg:p-24" },
                          [
                            _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("lastName")))]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.orderInfo.lastname))
                              ])
                            ]),
                            _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("firstName")))
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.orderInfo.firstname))
                              ])
                            ]),
                            _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("country")))]),
                              _c("span", [_vm._v(_vm._s(_vm.countryName))])
                            ]),
                            _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("phone")))]),
                              _c("span", [_vm._v(_vm._s(_vm.orderInfo.phone))])
                            ]),
                            _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                              _c("span", [_vm._v(_vm._s(_vm.orderInfo.email))])
                            ])
                          ]
                        ),
                        _vm.orderInfo.order_requirements
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-grey-darker text-sm py-24 lg:p-24 border-t border-grey-lighter"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "template__info-group" },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$t("orderRequirements"))
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.orderInfo.order_requirements)
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "hotel-orders" },
                      [
                        _c("h4", { staticClass: "py-24 font-bold" }, [
                          _vm._v(_vm._s(_vm.$t("roomTypeAndOrders")))
                        ]),
                        _c(
                          "blockCard",
                          { attrs: { noPadding: "" } },
                          _vm._l(_vm.roomsGroupByRoomName, function(
                            room,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "hotel-orders__order py-24 lg:p-24"
                              },
                              [
                                _vm._l(room, function(plan, index) {
                                  return _c(
                                    "div",
                                    { key: plan.name + "-" + index },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "text-grey-darker mb-16"
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$convertI18nObj(
                                                  plan.room_name_i18n,
                                                  plan.room_name
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hotel-orders__container rounded-sm shadow-md border-1 mb-16"
                                        },
                                        [
                                          _c("hotelOrder", {
                                            attrs: {
                                              plan: plan,
                                              orderInfo: _vm.orderInfo
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                _c("h6", { staticClass: "mb-16" }, [
                                  _vm._v(_vm._s(_vm.$t("cancelPolicy")))
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "whitespace-pre-wrap text-grey-dark text-sm leading-normal"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$convertI18nObj(
                                            room[0].cancel_policy.content_i18n,
                                            room[0].cancel_policy.content |
                                              _vm.contentParser
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _vm.hasAddons
                      ? _c(
                          "div",
                          { staticClass: "addon-orders" },
                          [
                            _c("h4", { staticClass: "py-24 font-bold" }, [
                              _vm._v(_vm._s(_vm.$t("addAddons")))
                            ]),
                            _c(
                              "blockCard",
                              { attrs: { noPadding: "" } },
                              _vm._l(_vm.orderInfo.addons, function(
                                addon,
                                key,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "addon-orders__order py-24 lg:p-24"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "addon-orders__container rounded-sm shadow-md border-1 mb-16"
                                      },
                                      [
                                        _c("addonOrder", {
                                          attrs: {
                                            addon: addon,
                                            orderInfo: _vm.orderInfo
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.hasAddonCancelPolicy(addon)
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "addon-orders__order__cancel-policy"
                                          },
                                          [
                                            _c("h6", { staticClass: "mb-16" }, [
                                              _vm._v(
                                                _vm._s(_vm.$t("cancelPolicy"))
                                              )
                                            ]),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "whitespace-pre-wrap text-grey-dark text-sm leading-normal"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("contentParser")(
                                                        addon.cancel_policy
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "blockCard",
                      {
                        staticClass: "hotel-info",
                        attrs: { title: _vm.$t("hotelInfo") }
                      },
                      [
                        _c("div", { staticClass: "template__info-group" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("hotel.name")))]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$convertI18nObj(
                                  _vm.orderInfo.hotel.name_i18n,
                                  _vm.orderInfo.hotel.name
                                )
                              )
                            )
                          ])
                        ]),
                        this["hotel/hotelDisplaySetting"].phone
                          ? _c("div", { staticClass: "template__info-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("hotel.phone")))
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.orderInfo.hotel.phone))
                              ])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "template__info-group" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("hotel.address")))
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.orderInfo.hotel.address))
                          ])
                        ]),
                        _c("div", { staticClass: "hotel-info__map" }, [
                          this["hotel/hotelLocatedBy"]
                            ? _c("iframe", {
                                staticStyle: { border: "0" },
                                attrs: {
                                  src:
                                    "https://www.google.com/maps/embed/v1/place?q=" +
                                    encodeURI(this["hotel/hotelLocatedBy"]) +
                                    "&key=" +
                                    _vm.googleMapKey,
                                  width: "100%",
                                  height: "320",
                                  frameborder: "0",
                                  allowfullscreen: ""
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    )
                  ],
                  1
                )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }